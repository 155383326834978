.back-and-forth {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-button,
    .forth-button {
        background-color: #ccc;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
          pointer-events: none;
          cursor: not-allowed;
        }
    }
}