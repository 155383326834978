@import "../../../scss/Variables.scss";
#home-teacher{height: 100%;
   .signIn-container{height: 100%; background-color: white; display: flex;
    .block{
        &.left{width: 60%;
          img{display: block; margin: 16px 0 64px 24px;}
        }
        &.right{width: 40%; display: flex;  align-items: flex-start;
          img{display: block; width: 100%; max-width: 500px; height: auto; margin-left: auto;}
        }
      }

      .signIn{ padding-left: 24px;
        .header{margin-bottom: 64px;
            h1{font-family: "Roboto-Light"; font-size: 4.5em;color: $colorPrimary; padding-bottom: 32px;}
          p{font-size: 32px; font-family: "Roboto-Light"; color: $colorPrimary;}
        }
      }
    
      .form{width: 50%; min-width: 300px; 
        input{display: block; margin-bottom: 32px; font-family: "Roboto-Light"; padding: 16px; border:none; border-bottom: 2px solid $undefined; background: none; font-size: 32px;
          &:focus{outline: none;}
        }
        .error{display: flex; align-items:center; margin-bottom: 32px;
          span.material-icons{font-size: 32px; color: $colorError; margin-right: 8px;}
          span.text{ font-family: "Roboto-Light"; font-size: 1.2em;}
        }
        .btn{ width: 270px; padding: 16px 0;
          .label{font-family: "Roboto-Light"; font-size: 24px;}
        }
      }

    }
}
