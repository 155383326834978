@import "../../../../../scss/Variables.scss";

.bar-link-container {
        border-radius: 10px;
        box-shadow: 2px 2px 1px rgba($color: #000000, $alpha: 0.2); 
        padding: 16px;
        background-color: $background-color-secondary;

        .bar-link-text {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 1em;
                color: $defaultFontColor;
        }
}