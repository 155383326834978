@import "../../../scss/Variables.scss";
$blue: #8AC0EC;
$blue-light: #CBE1EA;
$alert: #FF6138;
.classroom-modules-overview {

    .classroom-navigation {
        display: flex;
        flex-direction: row;
        border-bottom: 4px solid #cfe0e9;
        padding-bottom: 10px;
        margin-top: 40px;
        margin-bottom: 40px;
        color: $defaultFontColor;;
        h1{
            display: flex;
            align-items: center;
            margin-right: 30px;
        }

        .select {
            width: 200px;
            height: 50px;
            margin-right: 30px;
            border-radius: 5px;
            border-color: #cfe0e9;
            font-size: 1.2em
        }

        .view-groups{
            display: flex;
            align-items: center;
            margin-left: 32px;
            padding: 0 16px;
            border-radius: 25px;
            border: 2px solid $blue;
            
            span{
                &:first-child{
                    font-size: 1.2em;
                }
                &.icon{
                    margin-left: 16px;
                }
            }
            &:hover{
                background-color: $blue;
                span{color: white;}
            }
        }
    }

    .manuel {
        display: flex;
        flex-direction: row;
        margin: 10px;
        align-items: center;
        > div {
            margin-right: 16px;
        }
        .box {
            display: block;
            min-width: 50px;
            min-height: 20px;
            max-width: 50px;
            max-height: 20px;
            margin: 5px;
        }

        .validated-box {
            background-color: $blue;
        }

        .in-progress-box {
            background-color: $blue-light;
            border: 1px dashed $undefined;
            box-sizing: border-box;
        }
        .objective-not-started-box {
            background-color: white;
            border: 1px dashed $undefined;
            box-sizing: border-box;
        }

        .circle {
            display: block;
            min-width: 20px;
            min-height: 20px;
            max-height: 20px;
            border-radius: 50%;
            border: 1px solid white;
            margin: 5px;
        }

        .has-improved {
            background-color: var(--color-correct);
        }

        .has-difficulties {
            background-color: $alert;
        }
    }

    .overview {
        .table {
            display: flex;
            .column {
                margin-top:48px;
                .cell {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    border: 1px solid $blue;
                    border-bottom: none;
                    width: 200px;
                    height: 50px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    background-color: white;
                    a{
                        display: flex;
                        width: calc(100% - 16px);
                        height: 100%;
                        padding: 0 8px;
                        .name-container{
                            display: flex;
                            align-items: center;
                            > span{
                                &:first-child{
                                    color: $colorPrimary;
                                    background-color: #f1f1f1;
                                    font-size: 18px;
                                    border-radius: 100%;
                                    padding: 4px 4px;
                                    margin-right: 8px;
                                    display: none;
                                }
                                &.name{
                                    display: flex;
                                    flex-direction: column;
                                    font-weight: 600;
                                    text-align: left;
                                    span{
                                        display: none;
                                        font-size: 0.9em;
                                        font-weight: initial;
                                    }
                                }

                            }
                        }
                        &:hover{
                            .name-container{
                                > span{
                                    &:first-child{
                                        display: block;
                                    }
                                    &.name{
                                        span{
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:first-child {
                        height: 60px;
                        position: relative;
                        background-color: $blue-light;
                        span {
                            font-weight: 600;
                            position: absolute;
                            &:first-child {
                                bottom: 8px;
                                left: 16px;
                            }
                            &:nth-child(2) {
                                top: 8px;
                                right: 16px;
                            }
                            &.split {
                                width: 160px;
                                height: 1px;
                                transform: rotate(21.5deg);
                                background-color: $blue;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: 1px solid $blue;
                    }

                    &.inDifficulty {
                        position: relative;
                    }
                }
            }
            .rows-container {
                width: 100%;
                padding-bottom: 8px;
                //  simpleBar
                > div {
                    scroll-behavior: smooth;
                    .simplebar-wrapper {
                        padding: 0px 0px 20px 0;
                    }
                    .simplebar-horizontal {
                        padding: 2px 0;
                        background-color: $blue-light;
                        &.simplebar-hover {
                            cursor: pointer;
                        }
                        &:hover {
                            pointer-events: initial;
                        }
                        .simplebar-scrollbar {
                            top: 2px;
                            border: 1px solid white;
                            border-radius: 10px;
                            box-sizing: border-box;
                            padding: 4px 0;
                            &::before {
                                background-color: $blue;
                                top: 0;
                            }
                        }
                    }
                }

                .container {
                    width: max-content;
                    margin-top: 48px;
                    .row {
                        display: flex;
                        .cell {
                            width: 60px;
                            height: 50px;
                            box-sizing: border-box;
                            color: white;
                            position: relative;
                            .tool-tip {
                                display: none;
                                align-items: center;
                                width: max-content;
                                max-width: 200px;
                                height: 30px;
                                padding: 16px;
                                position: absolute;
                                left: 45px;
                                top: -45px;
                                background-color: #444;
                                border-radius: 10px 10px 10px 0;
                                color: white!important;
                                z-index: 100;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 13px;
                            }
                            &:hover{
                                .tool-tip{
                                    display: flex;
                                }
                            }
                            .alert {
                                width: 15px;
                                height: 15px;
                                border-radius: 100%;
                                &.--hasDifficulties {
                                    background-color: #FF6138;
                                    border: 1px solid white;
                                }
                                &.--negative {
                                    background-color: #FF6138;
                                    border: 1px solid white;
                                }
                                &.--positive {
                                    background-color: $colorCorrect;
                                    border: 1px solid white;
                                }
                                &.--hasImproved {
                                    background-color: $colorCorrect;
                                    border: 1px solid white;
                                }
                            }
                            &.none {
                                background: white;
                                border: 1px dashed $undefined;
                            }
                            &.completed {
                                background-color: $blue;
                            }
                            &.inProgress {
                                border: 1px dashed $undefined;
                                background-color: $blue-light;
                            }
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &.th {
                            .cell {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 62px;
                                margin: 0;
                                height: 61px;
                                border: 1px solid $blue;
                                border-left: none;
                                background-color: white;
                                span {
                                    color: $defaultFontColor;
                                    &.text {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        height: 100%;
                                        font-size: 14px;
                                        font-weight: 600;
                                        position: relative;
                                    }
                                }
                                &#start {
                                }
                                &#end {
                                    span.border-bottom {
                                        display: block;
                                    }
                                }
                            }
                        }
                        &.tr {
                            align-items: center;
                            .cell {
                                height: 42px;
                                margin: 4px 1px;
                            }
                        }
                    }
                }
            }
        }
        .nav {
            display: flex;
            justify-content: flex-end;
            margin: 16px 0 0 0;
            a {
                &:first-child {
                }
                &:last-child {
                }
            }
        }
    }
}
