@import "../../../scss/Variables.scss";

.group-characteristics {
    padding: 10px;
    margin-left: 20px;
    margin-top: 15px;

    * {
        box-sizing: border-box;
    }

    .--group-1 {
        background: #dfedf2;
    }
    .--group-2 {
        background: #e8d8e7;
    }
    .--group-3 {
        background: #cce8d6;
    }
    .--group-4 {
        background: #edb18e;
    }
    .--group-5 {
        background: #dcde90;
    }

    .header {
        display: flex;

        .circle {
            border-radius: 19px;
            height: 30px;
            width: 30px;

            &:after {
                @include material-icons;
                content: "expand_more";
                color: rgb(53, 50, 48);
                position: relative;
                top: 3px;
                margin-left: 3px;
            }
        }

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2c3e50;
            margin-left: 10px;
            text-transform: capitalize;
        }
    }

    .characteristic {
        font-size: 14px;
        line-height: 16px;
        color: #2c3e50;
        border-left: 3px solid #a7cddc;
        padding-left: 15px;
        margin-left: 15px;

        &.characteristic-border-1 {
            border-left: 3px solid #dfedf2;
        }
        &.characteristic-border-2 {
            border-left: 3px solid #e8d8e7;
        }
        &.characteristic-border-3 {
            border-left: 3px solid #cce8d6;
        }
        &.characteristic-border-4 {
            border-left: 3px solid #edb18e;
        }
        &.characteristic-border-5 {
            border-left: 3px solid #dcde90;
        }

        &.selected {
            display: grid;
            row-gap: 5px;
            column-gap: 2px;

            & > * {
                padding: 20px;
                text-align: center;
            }

            &:not(.selected-student) {
                grid-template-columns: auto max-content;

                & > *:nth-child(2n + 1) {
                    border-radius: 12px 0 0 12px;
                }
                & > *:nth-child(2n) {
                    border-radius: 0 12px 12px 0;
                }
            }

            &.selected-student {
                grid-template-columns: auto max-content max-content;

                & > *:nth-child(3n + 1) {
                    border-radius: 12px 0 0 12px;
                }
                & > *:nth-child(3n) {
                    border-radius: 0 12px 12px 0;
                }
            }
        }

        .detailed-characteristic-title {
            font-weight: bold;
            font-size: 13px;
            color: #2c3e50;
            text-align: left;
        }

        .duration {
            font-size: 12px;
            color: #2c3e50;
        }

        .characteristic-details__student-value {
            font-weight: bold;
            font-size: 13px;
            color: white;
            background-color: #60a4bf;
        }
    }
}
