@import "../../scss/Variables.scss";

.player-header {
    box-sizing: border-box;
    padding: 13px 30px;
    width: 100%;
    height: 100%;
    background-color: $colorPrimary;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
