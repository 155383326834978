.breadcrumbs {
    display: flex;

    .breadcrumbs__item {
      color: var(--color-primary);

      &:hover {
          cursor: pointer;
      }
    }

    .breadcrumbs__separator {
        color: black;
        padding: 0 8px;
        user-select: none;
    }
}
