
@import '../../../../scss/Variables.scss';

$bgColor: #e8e8e8;

.tabs-container{ width: 100%; max-width: 300px;
    .buttons{ display: flex; position: relative; z-index: 10;
        button{ width: 45%; margin: 0 8px 0 0; padding: 16px; background-color: white; border-radius: 29px 29px 0 0; font-size: 1em; font-weight: 600; font-family: "Roboto",sans-serif; transform: translateY(8px); transition: all 0.2s ease-out; position: relative;
            span{color: $undefined}
            &.active{transform: translateY(0);
                span{color: $text-color-primary;}
            }
        }
    }
    .tab-content{ padding: 24px; width: calc(100% - 42px); min-height: 340px; border-radius: 0 29px 29px 29px; background-color: white; position: relative; z-index: 100; box-shadow: 2px 2px 1px rgba($color: #000000, $alpha: 0.2);
        section{ width: 100%; display: none; min-height: 360px;
            &.active{display: block;}
            .classInfo{display: flex; align-items: center; margin: 0 0 16px 0;
                .classLink{display: flex; align-items: center; margin: 0 0 0 auto;  font-size: 0.8em; font-weight: 500; font-size: 0.9em;
                    &:hover{
                        .text{text-decoration: underline;}
                    }
                }
                .classCode{font-size: 0.9em;}
            }
            .no-data{background-color: white; padding: 16px; font-size: 1em;}
        }
    }
}