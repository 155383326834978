@import "_variables";
@import "../../../../scss/Variables.scss";

#student-details-container {
    .top-container {
        display: flex;
        align-items: center;
        margin: 0 0 24px 24px;
        > *:not(:last-child) {
            margin-right: 48px;
        }
        .back-btn {
            background-color: #ccc;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            padding: 8px;
        }
    }
    h1 {
        display: flex;
        width: 100%;
        span {
            &:first-child {
            }
            &:last-child {
                display: flex;
                align-self: flex-end;
                font-family: "Roboto";
                margin-left: auto;
                font-size: 14px;
                padding-bottom: 8px;
            }
        }
    }
    h2 {
        font-family: "Roboto";
        margin-bottom: 16px;
        font-size: 32px;
    }

    #student-name-container {
        width: 60%;
        margin: 16px 16px;
        padding: 8px 0;
        border-bottom: 4px solid $light-blue;
        display: flex;
        align-items: center;
        #student-name {
            margin: 0 8px;
            font-size: 2.5em;
            color: $primary-student;
        }
    }

    #card-container {
        width: 90%;
        margin: 8px auto;
        .card {
            margin: 48px 16px;
            &.small {
                width: calc(40% - 32px);
            }
            &.medium {
                width: calc(60% - 32px);
            }
            &.large {
                width: 100%;
                margin: 48px 0;
            }
        }
        .top-cards {
            display: flex;
            flex-wrap: wrap;
        }

        // Custom select
        .details-custom-select {
            width: 270px;
            position: relative;
            background-color: #f4f4f4;
            height: 24px;
            margin: 0 0 24px 0;
            cursor: pointer;
            > button {
                padding: 9px 8px;
                position: absolute;
                right: -30px;
                top: 0;
                background-color: #f4f4f4;
                border-radius: 5px;
                border: 1px solid #333;
            }
            > .label {
                padding: 8px;
                background-color: #f4f4f4;
                border: 1px solid #333;
                border-radius: 5px;
                span {
                    &.icon {
                        position: absolute;
                        right: 8px;
                        top: 5px;
                    }
                    &.open {
                        display: block;
                    }
                    &.close {
                        display: none;
                    }
                }
            }
            .select {
                width: 100%;
                display: none;
                position: absolute;
                top: 35px;
                left: 0;
                z-index: 100;
                background-color: #f4f4f4;
                border: 1px solid #ccc;
                box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
                .option {
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    border-bottom: 1px solid #ccc;
                    &:hover {
                        background-color: white;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    .icon {
                        margin: 0 8px;
                    }
                    &.currentObjective .icon {
                        width: 20px;
                        height: 8px;
                        border: 2px dashed #8ac0ec;
                        border-radius: 5px;
                        background-color: #cfe0e9;
                        box-sizing: border-box;
                    }
                    &.difficulties .icon {
                        width: 10px;
                        height: 10px;
                        background-color: #ff6138;
                        border-radius: 100%;
                    }
                    &.facilities .icon {
                        width: 10px;
                        height: 10px;
                        background-color: $colorCorrect;
                        border-radius: 100%;
                    }
                    &.validatedObjectives .icon {
                        width: 20px;
                        height: 8px;
                        background-color: #8ac0ec;
                        border-radius: 5px;
                    }
                }
            }
            &.show {
                .label {
                    span {
                        &.text {
                        }
                        &.open {
                            display: none;
                        }
                        &.close {
                            display: block;
                        }
                    }
                }
                .select {
                    display: block;
                }
            }
        }
        // -----
    }

    .progression-container {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;

        > button {
            position: absolute;
            right: -5px;
            top: 5px;
        }
        .student-progression > .container .name-container {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        #student-name-container {
            #student-name {
            }
            #card-container {
                flex-direction: column;
            }
        }
    }
}
