@import "../../scss/Variables.scss";

.player__nav-breadcrumbs {
    display: flex;

    > div:not(:last-of-type) {
        margin-right: 20px;
    }

    .activity-selector {
        display: flex;

    .activity-selector__tooltip {
        position: relative;
        cursor: help;

        .activity-selector__icon {
            font-size: 24px;
            color: white;
            margin-left: 4px;
        }

        .activity-selector__info {
            opacity: 0;
            z-index: -1;
            min-width: 200px;
            position: absolute;
            top: 32px;
            left: -24px;
            background-color: #fff;
            padding: 16px;
            color: $defaultFontColor;
            @include borderRadius;
            text-align: left;
            border: 1px solid $colorPrimary;
        }

        &:hover {
            .activity-selector__info {
                opacity: 1;
                z-index: 100;

                &:after {
                    /*css triangle*/
                    content: "";
                    display: inline-block;
                    height: 0;
                    width: 0;
                    border-right: 5px solid transparent;
                    border-bottom: 9px solid #fff;
                    border-left: 5px solid transparent;
                    position: absolute;
                    top: -9px;
                    left: 31px;
                }
            }
        }
    }}
}
