@import '../../../../scss/Variables.scss';
.progress-bar-link {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $background-color-secondary;
    margin: 0 0 16px 0;
    padding: 0 8px;
    border-radius: 16px;
    box-shadow: 2px 2px 1px rgba($color: #000000, $alpha: 0.2);
    position: relative;
    isolation: isolate;
    overflow: hidden;

    .progressBar {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $colorPrimaryLight;
        height: 100%;
    }

    .progress-bar-text {
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: bold;
        color: $defaultFontColor;
        width: 100%;
        z-index: 1;

        .module-title {
            position: relative;
            width: 100%;
            padding: 0 0 0 8px ;
            // Show maximum 2 lines of the title so it doesn't bleed out
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .module-progression {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            margin: 0 0 0 auto;
            font-weight: lighter;
            border-radius: 10px;
        }
    }
}
