@import '../../scss/Variables.scss';

.dashboard { padding: 32px 24px; background-color: $background-color-secondary; height: calc(100% - 130px); position: relative; overflow: hidden;
    h1{
        font-family: "Roboto", sans-serif;
        font-size: 28px;
        font-weight: 600;
    }
    header{
        .breadcrumbs{margin: 0 0 16px 0;
            ul{
                li{display: inline-block; padding-right: 8px;
                    a{ color: $colorPrimary;
                        &:after{content:'-'; padding-left: 8px;}
                    }
                }
            }
        }
    }
    
    img { display: block; margin: 0 auto; @include adaptiveWidth(500); }
    .bold { font-weight: bold; }
    .links { display: flex;
        a { border: 1px solid black; font-weight: bold; padding: 8px 12px; margin-right: 12px; transition: background-color 0.25s; }
        a:hover { background-color: black; color: white; transition: background-color 0.5s; }
    }
}