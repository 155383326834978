@import "../../scss/Variables.scss";
$headerHeigt: 90px;

.header-top {
    height: $headerHeigt;
    padding:0;
    background-color: #fff;
    position: relative;
    z-index: 200;
    .container {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        .logo {
            margin: 0 0 0 24px;
        }
    }
}
/* Menu tablet landscape and desktop */
@media screen and (min-width: 769px) {
    .header-top {
        .container {
            height: $headerHeigt;
            .mobileMenuBtn {
                display: none;
            }
            .menu-container {
                margin: 0 0 0 auto;
                display: flex;
                align-items: center;
                height: 100%;
                nav {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    li {
                        display: inline-block;
                        margin: 0 24px;
                        position: relative;
                        a {
                            color: $colorPrimary;
                            font-size: 1em;
                            &:after {
                                content: "";
                                display: block;
                                width: 100%;
                                height: 2px;
                                position: absolute;
                                left: 0;
                                bottom: -10px;
                                transition: all 0.2s ease-out;
                            }
                            &:hover,
                            &.active {
                                transition: bottom 0.2s,
                                    background-color 0.2s ease-out;
                                font-weight: 600;
                                &:after {
                                    bottom: -4px;
                                    background-color: $colorPrimary;
                                }
                            }
                        }
                        &:last-child {
                            padding: 0;
                        }
                    }
                    button {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 24px;
                        border-left: 1px solid $neutralGray;
                        font-size: 1em;
                        font-family: "Roboto", sans-serif;
                        a{
                            display: flex;
                            align-items: center;
                            span {
                                color: $colorPrimary;
                                &.label{
                                    display: flex;
                                    align-items: center;
                                }
                                &.icon{
                                    margin: 0 4px 0 0;
                                    font-size: 20px;
                                }
                            }
                        }
                        &:hover {
                            color: $colorPrimary;
                            span.label{
                                font-weight: 600;
                            }
                        }
                    }
                }
                .closeMobileMenuBtn {
                    display: none;
                }
            }
        }
    }
}

/*Menu tablet portrait and smartphone*/
@media screen and (max-width: 768px) {
    .header-top {
        .container {
            .mobileMenuBtn {
                margin: 0 0 0 auto;
                span {
                    font-size: 32px;
                }
            }
            .menu-container {
                display: flex;
                width: 300px;
                height: 100%;
                align-items: center;
                position: fixed;
                right: 0;
                top: 0;
                background-color: #fff;
                box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
                transform: translate3d(300px, 0, 0);
                transition: all 0.2s ease-out;
                nav {
                    width: 100%;
                    li {
                        text-align: center;
                        padding: 24px 0;
                        
                    }
                    a {
                        font-size: 1.1em;
                        font-weight: 600;
                        &:after {
                            content: "";
                            width: 50px;
                            height: 2px;
                            display: block;
                            margin: 8px auto 0 auto;
                            background-color: $colorPrimary;
                        }
                    }
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 24px 0;
                        font-family: "Roboto", sans-serif;
                        font-size: 1.1em;
                        a{
                            span{
                                &.label{
                                    display: flex;
                                    align-items: center;
                                    .icon{
                                        margin: 0 4px 0 0;
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
                .closeMobileMenuBtn {
                    position: absolute;
                    top: 24px;
                    right: 24px;
                    span {
                        font-size: 32px;
                    }
                }
                &.show {
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}
