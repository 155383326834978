@import "../../../../scss/Variables.scss";

.quick-start {
    display: flex;
    align-items: center;
    margin-top: 25px;

    & > *:first-child {
        margin-right: 3px;
    }

    .quick-start__button {
        box-sizing: border-box;
        border: 2px solid #8ac0ec;
        border-radius: 30px;
        background-color: rgba(138, 192, 236, 0.08);
        padding: 15px 20px;
        display: flex;
        align-items: center;
        color: #56a3e1;
        font-size: 1rem;

        & > *:first-child {
            margin-right: 20px;
        }

        & > * {
            display: flex;
            align-items: center;
        }

        .quick-start__button--small-text {
            font-size: 0.875rem;
            color: rgba(86, 163, 225, 0.5);
        }

        .--set-width {
            width: 160px;
        }
    }
}

[data-reach-tooltip-trigger].quick-start__tooltip {
    color: rgba(86, 163, 225, 0.42);
}

[data-reach-dialog-overlay] {
    padding: 10px;
}

[data-reach-dialog-content].quick-start__modal {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 30px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 835px;
    width: auto;

    .material-icons {
        margin-right: 5px;
        position: relative;
        top: 4px;
    }

    .modal__title {
        flex: 0 0 auto;
        height: 90px;
        line-height: 90px;
        font-size: 1.5rem;
        text-align: center;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

        .modal__title--accent {
            color: #56a3e1;
        }
    }

    .modal__content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        padding: 30px;
        text-align: center;
        box-sizing: border-box;
        font-size: 1.125rem;
        position: relative;
        isolation: isolate;

        .content__code {
            font-size: 4rem;

            .content__code--number {
                color: #56a3e1;
            }
        }

        .content__time-left {
            color: rgba(78, 78, 78, 0.5);
        }

        .content__expired-code {
            position: absolute;
            top: 10px;
            bottom: 10px;
            left: 0;
            right: 0;

            .expired-code__overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: white;
                opacity: 0.95;
            }

            .expired-code__content {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2.25rem;

                > p {
                    display: flex;
                    align-items: center;
                    color: #4e4e4e;

                    .material-icons {
                        font-size: 1.5em;
                        position: relative;
                        top: -2px;
                        margin-right: 25px;
                        color: black;
                    }

                    .expired-code--cross {
                        position: relative;

                        &:after {
                            @include material-icons;
                            content: "cancel";
                            color: #ff0f00;
                            position: absolute;
                            top: 50%;
                            right: -3px;
                            transform: translateY(-50%);
                            font-size: 0.4em;
                            background-color: white;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    .modal__actions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        margin-right: 35px;
        
        .actions__restart {
            color: #4e4e4e;
            text-transform: uppercase;
            
            .material-icons {
                position: relative;
                top: 6px;
            }
        }

        .actions__close-button {
            color: white;
            background: #56a3e1;
            border: 1px solid #e0e0e0;
            text-transform: uppercase;
            box-sizing: border-box;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 18px 45px;
            margin-left: 25px;
        }
    }
}
