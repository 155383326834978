.bubble-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &.with-bot-avatar {
        margin-top: 20px;
    }

    &.right {
        align-self: flex-end;

        > .chat-bubble {
            border-radius: 30px 0 30px 30px;
        }
    }

    &.left {
        align-self: flex-start;

        > .chat-bubble {
            border-radius: 0 30px 30px 30px;
            margin-top: 10px;
        }
    }

    &.full-width {
        width: 100%;

        .chat-bubble {
            width: 100%;
        }
    }

    &.half-width {
        width: 100%;
        display: flex;
        justify-content: center;

        .chat-bubble {
            width: 50%;
        }
    }

    &:not(.full-width):not(.half-width) {
        width: -moz-fit-content;
        width: fit-content;

        > .chat-bubble {
            width: -moz-fit-content;
            width: fit-content;
        }
    }

    &.no-margin-bottom {
        margin-bottom: 0;
    }

    > .chat-bubble {
        padding: 24px;
    }

    > .chatbot-placeholder,
    > img {
        margin-right: 30px;
        width: 96px;
    }
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 30px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 30px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animated {
    animation-duration: 0.35s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.35s;
    -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
    opacity: 0;
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
