.group-body {
    display: flex;
    flex-direction: column;

    .clusters-container {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;

        .clusters__left {
            flex: 3 0 0;
            min-width: 800px;

            .groupe-circles {
                background: #d7e6f5;
                border-radius: 15px;
                border-radius: 15px;
            }
        }

        .clusters__right {
            flex: 1 0 0;
            max-width: 500px;

            h2 {
                font-family: "Roboto";
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 600;
            }

            .characteristics-container {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media (max-width: 1300px) {
    .group-body .clusters-container .clusters__right {
        max-width: 100%;
        flex-grow: 1;
        margin-bottom: 50px;

        .characteristics-container {
            flex-direction: row;
        }
    }
}
