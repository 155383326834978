@import "../../../scss/Variables.scss";

#init-path-modal {
    [data-reach-accordion] {
        button {
            display: block;
            margin: 0 auto 24px auto;
            font-family: "Roboto-Medium", sans-serif;
            span {
                color: $colorPrimary;
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }

    p {
        margin: 16px 0 0 0;
        font-family: "Roboto-Medium", sans-serif;
    }

    .icon-container{

        display: flex;
        justify-content: center;
        .icon{
            width: 52px;
            position: relative;
           
            span.student-number{
                position:absolute;
                right: -4px;
                top: 0;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $colorError;
                border-radius: 100%;
                color: white;
                font-size: 10px;
            }
            span.material-icons {
                width: 32px;
                height: 32px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $colorPrimary;
                color: $colorPrimary;
                padding: 8px;
                border-radius: 100%;
            }
        }
    }
    .buttons-container{
        display: flex;
        justify-content:flex-end;
        margin: 48px 0 0 0;
        button {
            width: fit-content;
            margin: 0 0 0 16px;
        }
    }
}

.classrooms-details-container {
    .classroom-title {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        h1 {
            color: var(--color-primary);
            border-bottom: 2px solid var(--color-primary);

            .idle span.icon {
                color: $colorPrimary;
            }

            .edit {
                input {
                    width: 150px;
                    padding: 0 8px;
                    background: none;
                    border: none;
                    font-size: 1em;
                    color: $colorPrimary;
                    font-weight: inherit;

                    &:focus {
                        outline: none;
                    }
                }

                button {
                    &:nth-child(2) {
                        margin: 0 8px 0 0;

                        .icon {
                            color: $colorCorrect;
                        }
                    }
                    &:nth-child(3) .icon {
                        color: #ff6138;
                    }
                }
            }
            .saving {
                width: 50px;
                height: 35px;
                position: relative;

                .evb-spinner__container {
                    width: auto;
                    height: auto;
                    right: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(40%);
                }
            }
        }

        .classCode {
            display: flex;
            margin: 0 0 0 32px;
            align-items: center;

            .code {
                font-family: "Roboto-Light";
                color: #6d6d6d;
                font-size: 1.7em;
                border-bottom: 2px solid #6d6d6d;
                padding-bottom: 4px;
            }

            .tool-tip {
                position: relative;
                margin: 0 0 0 8px;

                .text {
                    display: none;
                    position: absolute;
                    right: -295px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 250px;
                    background-color: #6d6d6d;
                    color: white;
                    padding: 16px;
                    border-radius: 10px;
                    z-index: 10;
                    text-align: center;

                    &:before {
                        content: "";
                        position: absolute;
                        left: -10px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 7.5px 10px 7.5px 0;
                        border-color: transparent #6d6d6d transparent
                            transparent;
                    }
                }

                &:hover .text {
                    display: block;
                }

                .material-icons {
                    color: #6d6d6d;
                }
            }
        }
    }

    .modules-container {
        display: flex;

        .classroom-details-cards-container {
            display: flex;
            flex-wrap: wrap;
            max-width: 80%;
            padding-top: 24px;
            flex: 1;

            .module-card {
                display: flex;
                flex-direction: column;
                width: 300px;
                margin: 16px;

                .card-title {
                    display: flex;
                    align-items: center;
                    padding: 8px 16px 0 16px;
                    max-width: 60%;
                    height: 50px;
                    margin-right: 15px;
                    background: white;
                    border-radius: 29px 29px 0px 0px;
                    box-shadow: 7px 8px 12px #e8e8e8;
                    border: none;
                    font-size: 1em;
                    font-weight: bold;
                    color: $defaultFontColor;
                }

                .card-content {
                    padding: 25px;
                    width: calc(100% - 50px);
                    margin-right: 15px;
                    background: white;
                    border-radius: 0px 29px 29px 29px;
                    box-shadow: 2px 2px 1px rgba($color: #000000, $alpha: 0.2);
                    border: none;
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    & > *:not(:last-child) {
                        margin-bottom: 30px;
                    }
                    .content{
                        > button{
                            margin: 0 0 16px 0;
                        }
                    }
                    > button{
                        margin-top: auto;
                    }
                    .progression {
                        .icon {
                            position: relative;
                            top: 7px;
                            margin-right: 10px;
                        }

                        .participants {
                            font-weight: bold;
                            margin-right: 5px;

                            &:before {
                                @include material-icons;
                                content: "person";
                                color: $colorPrimary;
                                position: relative;
                                top: 7px;
                                margin-right: 10px;
                                background-color: white;
                                font-size: 18px;
                                border-radius: 100%;
                                padding: 4px 4px;
                            }
                        }

                        .progression-state {
                            border-left: 2px solid skyblue;
                            margin-top: 15px;
                            padding-left: 15px;
                            margin-left: 11px;

                            > div {
                                display: flex;
                                margin-bottom: 16px;
                            }

                            .progression-difficulties:before {
                                @include material-icons;
                                content: "error";
                                color: #ff6138;
                                position: relative;
                                top: 7px;
                                margin-right: 10px;
                            }

                            .progression-improved {
                                margin-bottom: 15px;

                                &:before {
                                    @include material-icons;
                                    content: "circle";
                                    color: rgb(31, 177, 31);
                                    position: relative;
                                    top: 7px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .in-progress-module-handler:disabled {
                        color: inherit;
                    }
                }
            }
        }

        .students-with-difficulties {
            border: 3px solid #ff6138;
            border-radius: 29px;
            width: 20%;
            max-width: 300px;
            margin-top: 24px;
            align-self: flex-start;
            background-color: white;

            .title {
                border-bottom: 2px solid #ff6138;
                padding: 8px;
                font-weight: 600;
                display: flex;
                align-items: center;

                span {
                    margin-right: 8px;
                    color: #ff6138;
                }
            }

            .student-list {
                padding: 15px;

                a {
                    display: flex;
                    align-items: center;
                    height: 32px;
                    padding: 8px 0;

                    > span:first-child {
                        color: #ff6138;
                        background-color: #e6e6e6;
                        border-radius: 100%;
                        padding: 4px 4px;
                    }

                    .name {
                        display: flex;
                        flex-direction: column;
                        margin-left: 8px;

                        span:last-child {
                            display: none;
                            align-items: center;
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        .name {
                            span:first-child {
                                padding: 0 0 2px 0;
                            }

                            span:last-child {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}
