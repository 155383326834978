.chatbot-layout {
    //Reset
    * {
        box-sizing: border-box;
    }
    box-sizing: border-box;

    padding-left: 40px;
    width: 100%;
    min-width: 350px;
    min-height: 100%;
    display: flex;
    justify-content: center;

    // You'll break the chatbot greyed background if you add position here

    .chatbot-core {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 1rem;
        font-weight: normal;
        margin: 32px;
        max-width: 1046px;
        width: 100%;
        // This isolates the div that uses z-index, so that z-index doesn't blead out
        isolation: isolate;

        // You'll break the chatbot greyed background if you add position here

        .welcome-message-start {
            display: flex;
            justify-content: center;
            margin-top: 64px;

            button {
                border-radius: 50px;
                background-color: var(--chatbot-color-validate-btn);
                color: white;
                padding: 16px 32px;
            }
        }

        .current-exercise {
            z-index: 3;
            position: relative;

            .current-exercise-chat-bubble {
                width: 100%;
            }

            .current-instruction {
                margin-top: 10px;
                font-weight: 700;
                font-size: 18px;
            }

            .additional-instructions {
                padding: 20px;
                background-color: white;
                border-radius: 30px 0px 0px 0px;
                font-weight: 400;
                font-size: 18px;
                padding-bottom: 0px;
                b p {
                    margin-bottom: 8px;
                }
            }
        }

        .current-exercise__background-shader {
            background-color: rgba(0, 0, 0, 0.15);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            pointer-events: none;
        }
    }
}
